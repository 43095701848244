.custom-section-1 {
    display: flex;
    justify-content: center;

    .background-image {
      background-color: #aaa;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-size: cover;
      background-position: center;
      background-blend-mode: multiply;
      opacity: 0;
      transition: opacity 1s ease-in-out;
    }
  
    &.bg10 .bg10 { opacity: 1; }
    &.bg11 .bg11 { opacity: 1; }
    &.bg12 .bg12 { opacity: 1; }
    &.bg20 .bg20 { opacity: 1; }
    &.bg21 .bg21 { opacity: 1; }
    &.bg22 .bg22 { opacity: 1; }
    &.bg30 .bg30 { opacity: 1; }
    &.bg31 .bg31 { opacity: 1; }
    &.bg32 .bg32 { opacity: 1; }
    &.bg40 .bg40 { opacity: 1; }
    &.bg41 .bg41 { opacity: 1; }
    &.bg42 .bg42 { opacity: 1; }
    &.bg50 .bg50 { opacity: 1; }
    &.bg51 .bg51 { opacity: 1; }
    &.bg52 .bg52 { opacity: 1; }
    &.bg60 .bg60 { opacity: 1; }
    &.bg61 .bg61 { opacity: 1; }
    &.bg62 .bg62 { opacity: 1; }
  
    .bg10 { background-image: url("../res/bg-images/landing-images/10.webp"); }
    .bg11 { background-image: url("../res/bg-images/landing-images/11.webp"); }
    .bg12 { background-image: url("../res/bg-images/landing-images/12.webp"); }
    .bg20 { background-image: url("../res/bg-images/landing-images/20.webp"); }
    .bg21 { background-image: url("../res/bg-images/landing-images/21.webp"); }
    .bg22 { background-image: url("../res/bg-images/landing-images/22.webp"); }
    .bg30 { background-image: url("../res/bg-images/landing-images/30.webp"); }
    .bg31 { background-image: url("../res/bg-images/landing-images/31.webp"); }
    .bg32 { background-image: url("../res/bg-images/landing-images/32.webp"); }
    .bg40 { background-image: url("../res/bg-images/landing-images/40.webp"); }
    .bg41 { background-image: url("../res/bg-images/landing-images/41.webp"); }
    .bg42 { background-image: url("../res/bg-images/landing-images/42.webp"); }
    .bg50 { background-image: url("../res/bg-images/landing-images/50.webp"); }
    .bg51 { background-image: url("../res/bg-images/landing-images/51.webp"); }
    .bg52 { background-image: url("../res/bg-images/landing-images/52.webp"); }
    .bg60 { background-image: url("../res/bg-images/landing-images/60.webp"); }
    .bg61 { background-image: url("../res/bg-images/landing-images/61.webp"); }
    .bg62 { background-image: url("../res/bg-images/landing-images/62.webp"); }
  
    h3 {
      background: none;
      border: none;
      color: white;
      position: fixed;
      top:350px;
      font-size: 150px !important;
      text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
      margin: 0;
    }
    @media (max-width: 1300px) {
      h3 {
        position: fixed;
        top:300px;
        font-size: 120px !important;
      }
    }
    @media (max-width: 800px) {
      h3 {
        top:300px;
        font-size: 80px !important;
      }
    }
    @media (max-height: 800px) {
      h3 {
        position: fixed;
        top:230px;
        font-size: 120px !important;
      }
    }
    @media (max-height: 500px) {
      h3 {
        top: 130px !important;
        font-size: 100px !important;
      }
    }
    @media (max-width: 600px) {
      h3 {
        top:300px;
        font-size: 55px !important;
      }
    }
}

.landing-captions {
    position: fixed;
    top: 500px;
    margin: auto;
    width: fit-content;
    max-width: 40%;
    p {
      color: white;
      font-size: 40px;
      text-shadow: 2px 2px 20px black;
      span {
        animation: blink 0.5s infinite;
      }
      @keyframes blink {
        0% {opacity: 1}
        70% {opacity: 1}
        71% {opacity: 0;}
        100% {opacity: 0;}
      }
      transition: all 0.2s;
    }
    @media (max-width: 1300px) {
        p {
            font-size: 30px;
        }
    }
    @media (max-width: 800px) {
        p {
          font-size: 25px !important;
        }
    }
    @media (max-height: 800px) {
        p {
            font-size: 30px;
        }
    }
}

@media (max-width: 1300px) {
  .landing-captions {
    position: fixed;
    top: 350px !important;
    max-width: 70%;
  }
}
@media (max-height: 800px) {
  .landing-captions {
    position: fixed;
    top: 350px !important;
    max-width: 70%;
  }
}
@media (max-height: 500px) {
  .landing-captions {
    top: 200px !important;
  }
}