.shadowed-text {
    text-shadow: 2px 2px 8px rgba(0, 0, 0, 1);
}

.margin-auto {
    margin: auto;
}

.title-small {
    font-size: 30px;
}

@media (max-width: 800px) {
    .title-small {
        font-size: 20px;
    }
}

@media (max-width: 600px) {
    .title-small {
        font-size: 15px;
    }
}