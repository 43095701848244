.underline-anim {
    position: relative;
    overflow: hidden;
    z-index: 5;
    z-index: 5;
  
    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 2px;
      height: 2px;
      background-color: white;
      transition: opacity 1s, transform 300ms;
      opacity: 1;
      transform: translate3d(-110%, 0, 0);
      transform: translate3d(-110%, 0, 0);
    }
  
    &:hover::after, :focus::after {
      transform: translate3d(0, 0, 0);
    }
}

.slide-from-top {
  transform: translateY(-80%);
  opacity: .4;
  animation: slide-down .5s forwards ease-in-out;
  &.delay-200 {animation-duration: 200ms}
  &.delay-300 {animation-duration: 300ms}
  &.delay-400 {animation-duration: 400ms}
  &.delay-500 {animation-duration: 500ms}
  &.delay-600 {animation-duration: 600ms}
  @keyframes slide-down {
    0% {transform: translateY(-80%); opacity: .4;}
    20% {opacity: .4}
    100% {transform: translateY(0); opacity: 1}
  }
}

.slide-from-bottom {
  transform: translateY(80%);
  opacity: .4;
  animation: slide-up .5s forwards ease-in-out;
  &.delay-200 {animation-duration: 200ms}
  &.delay-300 {animation-duration: 300ms}
  &.delay-400 {animation-duration: 400ms}
  &.delay-500 {animation-duration: 500ms}
  &.delay-600 {animation-duration: 600ms}
  @keyframes slide-up {
    0% {transform: translateY(80%); opacity: .4;}
    100% {transform: translateY(0); opacity: 1}
  }
}

.slide-from-right {
  transform: translateX(80%);
  opacity: .4;
  animation: slide-left .5s forwards ease-in-out;
  &.delay-200 {animation-duration: 200ms}
  &.delay-300 {animation-duration: 300ms}
  &.delay-400 {animation-duration: 400ms}
  &.delay-500 {animation-duration: 500ms}
  &.delay-600 {animation-duration: 600ms}
  @keyframes slide-left {
    0% {transform: translateX(80%); opacity: .4;}
    100% {transform: translateX(0); opacity: 1}
  }
}

.slide-from-left {
  transform: translateX(-80%);
  opacity: .4;
  animation: slide-right .5s forwards ease-in-out;
  &.delay-200 {animation-duration: 200ms}
  &.delay-300 {animation-duration: 300ms}
  &.delay-400 {animation-duration: 400ms}
  &.delay-500 {animation-duration: 500ms}
  &.delay-600 {animation-duration: 600ms}
  @keyframes slide-right {
    0% {transform: translateX(-80%); opacity: .4;}
    100% {transform: translateX(0); opacity: 1}
  }
}