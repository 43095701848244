.projects-page {
    .sticky-header {
        position: fixed;
        top: 0;
        width: 100%;
        background-color: transparent;
        color: white;
        margin-top: 20px;
        text-align: center;
        z-index: 1000;

        a {
            color: white;
            text-decoration: none;
            cursor: pointer;
            user-select: none;
            font-family: "aerospace";
            font-size: 4rem;
            margin: 0;
            text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
        }
    }

    .project-section {
        position: relative;
        color: white;
        text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
        h3 {
            position: absolute;
            top: 40%;
            left: 50px;
            margin: 0;
            font-size: 50px;
            transition: all 1s;
            &.hidden {
                transform: translateX(-50vw);
            }
        }
        p {
            position: absolute;
            top: calc(40% + 80px);
            left: 50px;
            margin: 0;
            font-size: 40px;
            width: 40%;
            transition: all 1s;
            &.hidden {
                transform: translateX(-100vw);
            }
        }
        @media (max-width: 800px) {
            h3 {font-size: 35px}
            p {font-size: 25px; width: 70%}
        }
    }

    .hero {
        background-image: url("../res/images/66.jpg");
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        height: 100vh;
        min-height: 500px;
        box-shadow: inset 0 0 100px 20px black;
    }
    
    .bull {
        background-image: url("../res/images/2.jpg");
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        height: 100vh;
        min-height: 500px;
        box-shadow: inset 0 0 100px 20px black;
    }

    .sawysawy {
        background-image: url("../res/images/61.jpg");
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        height: 100vh;
        min-height: 500px;
        box-shadow: inset 0 0 100px 20px black;
    }
}