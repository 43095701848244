.impact-bento {
    grid-template-areas:
    "g2 g3"
    "g1 g3";
}

.impact-bg1 {
    &::before {
        display: block;
        position: relative;
        content: '';
        left: -20%;
        width: 140%;
        height: 100%;
        transform: skew(10deg);
        background-image: url('../images/SADA1.jpg');
        background-size: cover;
        background-position: center;
    } 
}

.impact-bg2 {
    &::before {
        display: block;
        position: relative;
        content: '';
        left: -20%;
        width: 140%;
        height: 100%;
        transform: skew(10deg);
        background-image: url('../images/2.jpg');
        background-size: cover;
    } 
}

.impact-bg3 {
    &::before {
        display: block;
        position: relative;
        content: '';
        left: -20%;
        width: 140%;
        height: 100%;
        transform: skew(10deg);
        background-image: url('../images/54.jpg');
        background-size: cover;
        background-position: center;
    } 
}