.trapezoid-bento {
    position: fixed;
    width: 110vw;
    height: 100%;
    top: 0;
    left: -5vw;
    display: grid;
    grid-gap: 8px;
    &.up {
        transform: perspective(200px) rotateX(-10deg); 
    }
    &.down {
        transform: perspective(200px) rotateX(10deg); 
    }
    transform-style: preserve-3d;
}

.trapezoid-section {
    &.up {
        transform: none !important;
    }
}

.trapezoid-img {
    &.up {
        transform: rotateX(10deg);
    }
    &.down {
        transform: perspective(200px) rotateX(-10deg); 
    }

    &.background-img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}