.landing-nav {
    position: fixed;
    bottom: 30px;
    z-index: 3;
    width: 100vw;
    text-align: center;
    display: flex;
    justify-content: center;
    width: 100vw;
    left: 0;

    p {
      font-size: 30px;
      color: white;
      margin: 0 10px 0 10px;
    }
  
    .nav-button {
      background: none;
      border: none;
      margin: 0 10px 0 10px;
      font-size: 30px;
      color: white;
      cursor: pointer;
      transition: all 0.2s;
      width: fit-content;
      &:hover {
        transform: scale(1.05);
      }
    }
}
@media (max-width: 1000px) {
 .landing-nav {.nav-button{font-size: 20px;} p {font-size: 20px;}}
}
@media (max-width: 750px) {
  .landing-nav {
    background: rgba($color: #000000, $alpha: 0.8);
    display: flex;
    flex-direction: column;
    bottom: -260px;
    padding-top: 30px;
    padding-bottom: 30px;
    box-shadow: 0 0 50px 50px rgba($color: #000000, $alpha: 0.8);
    transition: bottom 0.2s;
    p{display: none}
    &:hover {
      bottom: 0;
    }
    .nav-button {
      margin: 0 auto 20px auto;
    }
    &::before {
      position: relative;
      content: '';
      width: 60vw;
      height: 3px;
      left: 20vw;
      top: -50px;
      background-color: white;
      z-index: 3;
    }
  }
}