.sticky-header {
    position: fixed;
    top: 0;
    width: 100%;
    background-color: transparent;
    color: white;
    margin-top: 20px;
    text-align: center;
    z-index: 1000;
    box-shadow: none;
    
    h1 {
      width: fit-content;
      margin: auto;
      user-select: none;
      font-family: arial;
      font-size: 150px;
      text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
      cursor: pointer;
      transform: translateY(200px);
      transition: transform 0.5s;
      &.animated {animation: headerAnim 1s forwards;}
    }
    @media (max-width: 1300px) {
      h1 {
        font-size: 110px;
        transform: translateY(200px);
      }
    }
    @media (max-height: 800px) {
      h1 {
        font-size: 110px;
        transform: translateY(200px);
      }
    }
    @media (max-width: 550px) {
      h1 {
        font-size: 70px;
        transform: translateY(200px);
        &.animated {animation: headerAnimMobile 1s forwards;}
      }
    }
    @media (max-height: 400px) {
      h1 {
        font-size: 90px;
      }
    }

    @keyframes headerAnim{
      0% {
        transform: translateY(200px);
      }
      100% {
        transform: translateY(0px);
      }
    }
    @keyframes headerAnimMobile{
      0% {
        transform: translateY(200px);
      }
      100% {
        transform: translateY(0);
      }
    }
  }

.whatsapp-icon {
    width: 50px;
    position: fixed;
    object-fit: contain;
    top: 40px;
    right: 30px;
    filter: drop-shadow(5px 5px 5px black);
    transition: all 0.2s;
    cursor: pointer;
    &:hover {
      transform: scale(1.05);
    }
}

.phone-number {
  background-color: white;
  color: black;
  position: fixed;
  top: 100px;
  right: 30px;
  width: 200px;
  padding: 8px;
  border-radius: 5px;
  overflow: hidden;
  transition: all 0.2s;
  text-wrap: nowrap;
  &.collapsed {
    width: 0;
    padding: 0;
  }
}