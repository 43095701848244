.privacy-policy-page {
    margin: 0;
    padding: 30px;
    background-color: white;
    width: 100%;

    h1 {
        text-align: center;
    }

    .text-body {
        margin: auto;
        width: clamp(300px, 70vw, 800px);
    }
}