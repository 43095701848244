.page {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    
}

.page::after {
    content: '';
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 2;
    box-shadow: inset 0 -100px 150px black;
    pointer-events: none;
}

img.background-image {
    &.full-page {
        position: fixed;
        object-fit: cover;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        z-index: -1;
    }
    
}